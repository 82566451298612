import layout from '../view/layout'

const router = {
  name: 'user',
  path: 'user',
  component: layout,
  meta: {
    title: '管理员',
    ico: 'el-icon-user-solid',
    authkey: 'user'
  },
  children: [
    {
      name: 'user_index',
      path: 'index',
      component: () => import('../view/user/index'),
      meta: {
        route_name: 'user_index',
        title: '账号管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'user_index'
      }
    },
    {
      name: 'user_add',
      path: 'add',
      component: () => import('../view/user/add'),
      meta: {
        route_name: 'user_index',
        title: '账号添加',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'user_index'
      }
    },
    {
      name: 'user_group',
      path: 'group',
      component: () => import('../view/user/group'),
      meta: {
        route_name: 'user_group',
        title: '角色管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'user_group'
      }
    },
    {
      name: 'user_group_add',
      path: 'groupadd',
      component: () => import('../view/user/groupadd'),
      meta: {
        route_name: 'user_group',
        title: '群组添加',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'user_group'
      }
    }
  ]
}

export default router
