import goods from "@/service/goods";
import order from "@/service/order";
import merchant from "@/service/merchant";
import test from "@/service/test";
import member from "@/service/member";
import activity from "@/service/activity";
import course from "@/service/course";

export default {
  merchant,
  order,
  goods,
  test,
  member,
  activity,
  course
}
