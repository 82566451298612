import { fetch } from '@/utils/request';

// 活动
const activityIndex = (param, callback) => fetch('/admin/activity/index','GET', param, callback);
const activityDetail = (param, callback) => fetch('/admin/activity/detail','GET', param, callback);
const activityEdit = (param, callback) => fetch('/admin/activity/edit','POST', param, callback);
const activityDelete = (param, callback) => fetch('/admin/activity/del','POST', param, callback);
const activityRec = (param, callback) => fetch('/admin/activity/rec','POST', param, callback);

// 积分卡
const integralCardIndex = (param, callback) => fetch('/admin/integral/card/index','GET', param, callback);
const integralCardAdd = (param, callback) => fetch('/admin/integral/card/add','POST', param, callback);
const integralCarddel = (param, callback) => fetch('/admin/integral/card/del','POST', param, callback);

// 以书换物
const exchangeIndex = (param, callback) => fetch('/admin/exchange/index','GET', param, callback);
const exchangeShenhe = (param, callback) => fetch('/admin/exchange/shenhe','POST', param, callback);
const exchangeDelivery = (param, callback) => fetch('/admin/exchange/delivery','POST', param, callback);

export default {
  activityIndex,
  activityDetail,
  activityEdit,
  activityDelete,
  activityRec,
  integralCardIndex,
  integralCardAdd,
  integralCarddel,
  exchangeIndex,
  exchangeShenhe,
  exchangeDelivery
}
