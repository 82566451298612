import { fetch } from '@/utils/request';

// 商品分类
const menuIndex = (param, callback) => fetch('/admin/goods/menu/index','GET', param, callback);
const menuList = (param, callback) => fetch('/admin/goods/menu/menu','GET', param, callback);
const menuDetail = (param, callback) => fetch('/admin/goods/menu/detail','GET', param, callback);
const menuAdd = (param, callback) => fetch('/admin/goods/menu/add','POST', param, callback);
const menuEdit = (param, callback) => fetch('/admin/goods/menu/edit','POST', param, callback);
const menuDelete = (param, callback) => fetch('/admin/goods/menu/del','POST', param, callback);

// 商品规格模板
const specsTemplateIndex = (param, callback) => fetch('/admin/goods/specsTemplate/index','GET', param, callback);
const specsTemplateInfo = (param, callback) => fetch('/admin/goods/specsTemplate/detail','GET', param, callback);
const specsTemplateAdd = (param, callback) => fetch('/admin/goods/specsTemplate/add','POST', param, callback);
const specsTemplateEdit = (param, callback) => fetch('/admin/goods/specsTemplate/edit','POST', param, callback);
const specsTemplateDel = (param, callback) => fetch('/admin/goods/specsTemplate/del','POST', param, callback);

// 商品
const goodsIndex = (param, callback) => fetch('/admin/goods/index','GET', param, callback);
const goodsInfo = (param, callback) => fetch('/admin/goods/detail','GET', param, callback);
const goodsAdd = (param, callback) => fetch('/admin/goods/add','POST', param, callback);
const goodsEdit = (param, callback) => fetch('/admin/goods/edit','POST', param, callback);
const goodsDel = (param, callback) => fetch('/admin/goods/del','POST', param, callback);
const goodsShenhe = (param, callback) => fetch('/admin/goods/shenhe','POST', param, callback);
const goodsRec = (param, callback) => fetch('/admin/goods/rec','POST', param, callback);

// 书单
const bookListIndex = (param, callback) => fetch('/admin/book/list/index','GET', param, callback);
const bookListInfo = (param, callback) => fetch('/admin/book/list/detail','GET', param, callback);
const bookListAdd = (param, callback) => fetch('/admin/book/list/add','POST', param, callback);
const bookListEdit = (param, callback) => fetch('/admin/book/list/edit','POST', param, callback);
const bookListDel = (param, callback) => fetch('/admin/book/list/del','POST', param, callback);
const bookListShenhe = (param, callback) => fetch('/admin/book/list/shenhe','POST', param, callback);
const bookListRec = (param, callback) => fetch('/admin/book/list/rec','POST', param, callback);

// 商品评价
const evaluateIndex = (param, callback) => fetch('/admin/evaluate/index','GET', param, callback);
const evaluateDel = (param, callback) => fetch('/admin/evaluate/del','POST', param, callback);

export default {
  menuIndex,
  menuList,
  menuDetail,
  menuAdd,
  menuEdit,
  menuDelete,
  specsTemplateIndex,
  specsTemplateInfo,
  specsTemplateAdd,
  specsTemplateEdit,
  specsTemplateDel,
  goodsIndex,
  goodsInfo,
  goodsAdd,
  goodsEdit,
  goodsDel,
  goodsShenhe,
  goodsRec,
  evaluateIndex,
  evaluateDel,
  bookListIndex,
  bookListInfo,
  bookListAdd,
  bookListEdit,
  bookListShenhe,
  bookListDel,
  bookListRec
}
