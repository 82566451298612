import { fetch } from '@/utils/request';

// 基础
const cityTree = (callback) => fetch('/admin/common/getCityTree','GET',{}, callback);
const authLogin = (param, callback) => fetch('/admin/auth/login','POST',param, callback);
const authLogout = (callback) => fetch('/admin/auth/logout','POST',false, callback);
const myIndex = (callback) => fetch('/admin/my/index','GET',{}, callback);
const myUpdate = (param, callback) => fetch('/admin/my/update','POST',param, callback);
const myPrivs = (callback) => fetch('/admin/my/privs','GET',{}, callback);
const myUpdatePwd = (param, callback) => fetch('/admin/my/resetpwd','POST',param, callback);
const sysSet = (callback) => fetch('/admin/sys/set','GET',{}, callback);

// 文件
const FileUpload = (param, callback) => fetch('/admin/common/uploadimg','POST',param, callback);
const fileList = (param, callback) => fetch('/admin/common/filelist','GET',param, callback);
const filedel = (param, callback) => fetch('/admin/common/filedel','POST',param, callback);
const filesign = (param, callback) => fetch('/admin/common/filesign','POST',param, callback);

// 系统
const sysDetail = (callback) => fetch('/admin/sys/info','GET', {}, callback);
const sysEdit = (param, callback) => fetch('/admin/sys/update','POST', param, callback);

// 用户
const userIndex = (param, callback) => fetch('/admin/user/index','GET', param, callback);
const userDetail = (param, callback) => fetch('/admin/user/detail','GET', param, callback);
const userAdd = (param, callback) => fetch('/admin/user/add','POST', param, callback);
const userEdit = (param, callback) => fetch('/admin/user/edit','POST', param, callback);
const userDelete = (param, callback) => fetch('/admin/user/del','POST', param, callback);
const userShenhe = (param, callback) => fetch('/admin/user/shenhe','POST', param, callback);
const userVisitLog = (param, callback) => fetch('/admin/user/visitlog','GET', param, callback);

// 群组
const groupIndex = (param, callback) => fetch('/admin/group/index','GET', param, callback);
const groupDetail = (param, callback) => fetch('/admin/group/detail','GET', param, callback);
const groupAdd = (param, callback) => fetch('/admin/group/add','POST', param, callback);
const groupEdit = (param, callback) => fetch('/admin/group/edit','POST', param, callback);
const groupDelete = (param, callback) => fetch('/admin/group/del','POST', param, callback);

// 菜单
const menuIndex = (param, callback) => fetch('/admin/menu/index','GET', param, callback);
const menuDetail = (param, callback) => fetch('/admin/menu/detail','GET', param, callback);
const menuAdd = (param, callback) => fetch('/admin/menu/add','POST', param, callback);
const menuEdit = (param, callback) => fetch('/admin/menu/edit','POST', param, callback);
const menuDelete = (param, callback) => fetch('/admin/menu/del','POST', param, callback);

// 单页
const pageIndex = (param, callback) => fetch('/admin/page/index','GET', param, callback);
const pageDetail = (param, callback) => fetch('/admin/page/detail','GET', param, callback);
const pageEdit = (param, callback) => fetch('/admin/page/edit','POST', param, callback);

// 素材
const bannerIndex = (param, callback) => fetch('/admin/banner/index','GET', param, callback);
const bannerDetail = (param, callback) => fetch('/admin/banner/detail','GET', param, callback);
const bannerEdit = (param, callback) => fetch('/admin/banner/edit','POST', param, callback);
const bannerDelete = (param, callback) => fetch('/admin/banner/del','POST', param, callback);

// 七牛
const qiniuToken = (param, callback) => fetch('/admin/sys/qiniu','GET', param, callback);

export default {
  cityTree,
  authLogin,
  authLogout,
  myIndex,
  myUpdate,
  myPrivs,
  myUpdatePwd,
  sysSet,
  FileUpload,
  fileList,
  filedel,
  filesign,
  sysDetail,
  sysEdit,
  userIndex,
  userDetail,
  userAdd,
  userEdit,
  userDelete,
  userShenhe,
  userVisitLog,
  groupIndex,
  groupDetail,
  groupAdd,
  groupEdit,
  groupDelete,
  menuIndex,
  menuDetail,
  menuAdd,
  menuEdit,
  menuDelete,
  pageIndex,
  pageDetail,
  pageEdit,
  bannerIndex,
  bannerDetail,
  bannerEdit,
  bannerDelete,

  qiniuToken
}
