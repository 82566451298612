import layout from '../view/layout'

const router = {
  name: 'course',
  path: 'course',
  component: layout,
  meta: {
    title: '课程',
    ico: 'el-icon-video-camera-solid',
    authkey: 'course'
  },
  children: [
    {
      name: 'course_index',
      path: 'index',
      component: () => import('../view/course/course'),
      meta: {
        route_name: 'course_index',
        title: '课程列表',
        ico: '',
        isShow: true,
        authkey: 'course_index'
      }
    },
    {
      name: 'course_add',
      path: 'add',
      component: () => import('../view/course/course_add'),
      meta: {
        route_name: 'course_index',
        title: '课程添加',
        ico: '',
        isShow: false,
        authkey: 'course_index'
      }
    },
    {
      name: 'course_menu',
      path: 'menu',
      component: () => import('../view/course/menu'),
      meta: {
        route_name: 'course_menu',
        title: '课程分类',
        ico: '',
        isShow: true,
        authkey: 'course_menu'
      }
    },
    {
      name: 'course_teacher',
      path: 'teacher',
      component: () => import('../view/course/teacher'),
      meta: {
        route_name: 'course_teacher',
        title: '课程老师',
        ico: '',
        isShow: true,
        authkey: 'course_teacher'
      }
    },
    {
      name: 'course_teacher_add',
      path: 'teacher/add',
      component: () => import('../view/course/teacher_add'),
      meta: {
        route_name: 'course_teacher',
        title: '老师添加',
        ico: '',
        isShow: false,
        authkey: 'course_teacher'
      }
    },
    {
      name: 'course_video',
      path: 'video',
      component: () => import('../view/course/video'),
      meta: {
        route_name: 'course_video',
        title: '视频库',
        ico: '',
        isShow: true,
        authkey: 'course_video'
      }
    },
    {
      name: 'course_video_add',
      path: 'video/add',
      component: () => import('../view/course/course_add'),
      meta: {
        route_name: 'course_video',
        title: '视频添加',
        ico: '',
        isShow: false,
        authkey: 'course_video'
      }
    }
  ]
}

export default router
