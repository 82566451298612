import layout from '../view/layout'

const router = {
  name: 'sys',
  path: 'sys',
  component: layout,
  meta: {
    title: '系统',
    ico: 'el-icon-s-tools',
    authkey: 'sys'
  },
  children: [
    {
      name: 'sys_set',
      path: 'set',
      component: () => import('../view/sys/set'),
      meta: {
        route_name: 'sys_set',
        title: '系统设置',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'sys_set'
      }
    },
    {
      name: 'sys_menu',
      path: 'menu',
      component: () => import('../view/sys/menu'),
      meta: {
        route_name: 'sys_menu',
        title: '菜单管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'sys_menu'
      }
    },
    {
      name: 'sys_visitlog',
      path: 'visitlog',
      component: () => import('../view/sys/visitlog'),
      meta: {
        route_name: 'sys_visitlog',
        title: '访问日志',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'sys_visitlog'
      }
    },
    {
      name: 'sys_page',
      path: 'page',
      component: () => import('../view/sys/page'),
      meta: {
        route_name: 'sys_page',
        title: '单页管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'sys_page'
      }
    },
    {
      name: 'sys_banner',
      path: 'banner',
      component: () => import('../view/sys/banner'),
      meta: {
        route_name: 'sys_banner',
        title: '素材管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'sys_banner'
      }
    },
    {
      name: 'sys_banner_add',
      path: 'banner/add',
      component: () => import('../view/sys/banner_add'),
      meta: {
        route_name: 'sys_banner',
        title: '素材管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'sys_banner'
      }
    }
  ]
}

export default router
