import layout from '../view/layout'

const router = {
  name: 'member',
  path: 'member',
  component: layout,
  meta: {
    title: '会员',
    ico: 'el-icon-user-solid',
    authkey: 'member'
  },
  children: [
    {
      name: 'member_index',
      path: 'index',
      component: () => import('../view/member/index'),
      meta: {
        route_name: 'member_index',
        title: '会员列表',
        ico: '',
        isShow: true,
        authkey: 'member_index'
      }
    },
    {
      name: 'member_grade',
      path: 'grade',
      component: () => import('../view/member/member_grade'),
      meta: {
        route_name: 'member_grade',
        title: '会员等级',
        ico: '',
        isShow: true,
        authkey: 'member_grade'
      }
    },
    {
      name: 'member_grade_add',
      path: 'grade/add',
      component: () => import('../view/member/member_grade_add'),
      meta: {
        route_name: 'member_grade',
        title: '添加等级',
        ico: '',
        isShow: false,
        authkey: 'member_grade'
      }
    },
    {
      name: 'member_withdraw',
      path: 'withdraw',
      component: () => import('../view/member/withdraw'),
      meta: {
        route_name: 'member_withdraw',
        title: '会员提现',
        ico: '',
        isShow: true,
        authkey: 'member_withdraw'
      }
    }
  ]
}

export default router
