import layout from '../view/layout'

const router = {
  name: 'activity',
  path: 'activity',
  component: layout,
  meta: {
    title: '活动',
    ico: 'el-icon-place',
    authkey: 'activity'
  },
  children: [
    {
      name: 'activity_index',
      path: 'index',
      component: () => import('../view/activity/index'),
      meta: {
        route_name: 'activity_index',
        title: '活动列表',
        ico: '',
        isShow: true,
        authkey: 'activity_index'
      }
    },
    {
      name: 'activity_add',
      path: 'add',
      component: () => import('../view/activity/add'),
      meta: {
        route_name: 'activity_index',
        title: '活动添加',
        ico: '',
        isShow: false,
        authkey: 'activity_index'
      }
    },
    {
      name: 'activity_integralcard',
      path: 'integralcard',
      component: () => import('../view/activity/integral_card'),
      meta: {
        route_name: 'activity_integralcard',
        title: '积分卡',
        ico: '',
        isShow: true,
        authkey: 'activity_integralcard'
      }
    }
  ]
}

export default router
