import layout from '../view/layout'

const router = {
  name: 'test',
  path: 'test',
  component: layout,
  meta: {
    title: '测评',
    ico: 'el-icon-document-checked',
    authkey: 'test'
  },
  children: [
    {
      name: 'test_menu',
      path: 'menu',
      component: () => import('../view/test/menu'),
      meta: {
        route_name: 'test_menu',
        title: '分类管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'test_menu'
      }
    },
    {
      name: 'test_index',
      path: 'index',
      component: () => import('../view/test/index'),
      meta: {
        route_name: 'test_index',
        title: '测评列表',
        ico: '',
        isShow: true,
        authkey: 'test_index'
      }
    },
    {
      name: 'test_add',
      path: 'add',
      component: () => import('../view/test/add'),
      meta: {
        route_name: 'test_index',
        title: '测评添加',
        ico: '',
        isShow: false,
        authkey: 'test_index'
      }
    },
    {
      name: 'test_question',
      path: 'question',
      component: () => import('../view/test/question'),
      meta: {
        route_name: 'test_index',
        title: '测评添加',
        ico: '',
        isShow: false,
        authkey: 'test_index'
      }
    },
    {
      name: 'test_bind',
      path: 'bind',
      component: () => import('../view/test/bind'),
      meta: {
        route_name: 'test_index',
        title: '绑定',
        ico: '',
        isShow: false,
        authkey: 'test_index'
      }
    }
  ]
}

export default router
