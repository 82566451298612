import { fetch } from '@/utils/request';

// 订单
const orderIndex = (param, callback) => fetch('/admin/order/index','GET', param, callback);
const orderDetail = (param, callback) => fetch('/admin/order/detail','GET', param, callback);
const orderCancel = (param, callback) => fetch('/admin/order/cancel','POST', param, callback);
const orderDelivery = (param, callback) => fetch('/admin/order/delivery','POST', param, callback);
const orderSetump = (param, callback) => fetch('/admin/order/setump','POST', param, callback);
const orderRebook = (param, callback) => fetch('/admin/order/rebook','POST', param, callback);
const orderTrade = (param, callback) => fetch('/admin/order/trade','GET', param, callback);

// 退款
const refundDetail = (param, callback) => fetch('/admin/refund/detail','GET', param, callback); //详情
const refundAgree = (param, callback) => fetch('/admin/refund/agree','POST', param, callback); //退款同意/拒绝
const refundClose = (param, callback) => fetch('/admin/refund/close','POST', param, callback); //关闭退款

export default {
  orderIndex,
  orderDetail,
  orderCancel,
  orderDelivery,
  orderSetump,
  orderRebook,
  orderTrade,
  refundDetail,
  refundAgree,
  refundClose
}
