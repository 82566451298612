import { fetch } from '@/utils/request';

// 测评分类
const menuIndex = (param, callback) => fetch('/admin/test/menu/index','GET', param, callback);
const menuDetail = (param, callback) => fetch('/admin/test/menu/detail','GET', param, callback);
const menuDelete = (param, callback) => fetch('/admin/test/menu/del','POST', param, callback);
const menuEdit = (param, callback) => fetch('/admin/test/menu/edit','POST', param, callback);
const menuRec = (param, callback) => fetch('/admin/test/menu/rec','POST', param, callback);

// 测评
const testIndex = (param, callback) => fetch('/admin/test/index','GET', param, callback);
const testDetail = (param, callback) => fetch('/admin/test/detail','GET', param, callback);
const testDelete = (param, callback) => fetch('/admin/test/del','POST', param, callback);
const testEdit = (param, callback) => fetch('/admin/test/edit','POST', param, callback);
const testShenhe = (param, callback) => fetch('/admin/test/shenhe','POST', param, callback);
const testRec = (param, callback) => fetch('/admin/test/rec','POST', param, callback);
const testQuestionIndex = (param, callback) => fetch('/admin/test/question/index','GET', param, callback);
const testQuestionSave = (param, callback) => fetch('/admin/test/question/save','POST', param, callback);
const testBindGet = (param, callback) => fetch('/admin/test/bind/get','GET', param, callback);
const testBindSave = (param, callback) => fetch('/admin/test/bind/save','POST', param, callback);

export default {
  menuIndex,
  menuDetail,
  menuDelete,
  menuEdit,
  menuRec,
  testIndex,
  testDetail,
  testDelete,
  testEdit,
  testShenhe,
  testRec,
  testQuestionIndex,
  testQuestionSave,
  testBindGet,
  testBindSave
}
