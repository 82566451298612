import layout from '../view/layout'

const router = {
  name: 'order',
  path: 'order',
  component: layout,
  meta: {
    title: '订单',
    ico: 'el-icon-s-order',
    authkey: 'order'
  },
  children: [
    {
      name: 'order_index',
      path: 'index',
      component: () => import('../view/order/index'),
      meta: {
        route_name: 'order_index',
        title: '订单列表',
        ico: '',
        isShow: true,
        authkey: 'order_index'
      }
    },
    {
      name: 'order_detail',
      path: 'detail/:id',
      component: () => import('../view/order/detail'),
      meta: {
        title: '订单详情',
        ico: '',
        isShow: false,
        authkey: 'order_index'
      }
    },
    {
      name: 'order_refund_detail',
      path: 'refund/detail/:id',
      component: () => import('../view/order/refund_detail'),
      meta: {
        title: '退款详情',
        ico: '',
        isShow: false,
        authkey: 'order_index'
      }
    },
    {
      name: 'order_exchange',
      path: 'exchange',
      component: () => import('../view/order/exchange'),
      meta: {
        route_name: 'order_exchange',
        title: '以书换物',
        ico: '',
        isShow: true,
        authkey: 'order_exchange'
      }
    },
    {
      name: 'order_trade',
      path: 'trade',
      component: () => import('../view/order/trade'),
      meta: {
        route_name: 'order_trade',
        title: '交易列表',
        ico: '',
        isShow: true,
        authkey: 'order_trade'
      }
    }
  ]
}

export default router
