import { fetch } from '@/utils/request';

// 课程教师
const courseTeacherIndex = (param, callback) => fetch('/admin/course/teacher/index','GET', param, callback);
const courseTeacherDetail = (param, callback) => fetch('/admin/course/teacher/detail','GET', param, callback);
const courseTeacherEdit = (param, callback) => fetch('/admin/course/teacher/edit','POST', param, callback);
const courseTeacherDelete = (param, callback) => fetch('/admin/course/teacher/del','POST', param, callback);
const courseTeacherRec = (param, callback) => fetch('/admin/course/teacher/rec','POST', param, callback);

// 课程类目
const courseMenuIndex = (param, callback) => fetch('/admin/course/menu/index','GET', param, callback);
const courseMenuDetail = (param, callback) => fetch('/admin/course/menu/detail','GET', param, callback);
const courseMenuEdit = (param, callback) => fetch('/admin/course/menu/edit','POST', param, callback);
const courseMenuDelete = (param, callback) => fetch('/admin/course/menu/del','POST', param, callback);

// 视频库
const courseVideoIndex = (param, callback) => fetch('/admin/course/video/index','GET', param, callback);
const courseVideoDetail = (param, callback) => fetch('/admin/course/video/detail','GET', param, callback);
const courseVideoEdit = (param, callback) => fetch('/admin/course/video/edit','POST', param, callback);
const courseVideoDelete = (param, callback) => fetch('/admin/course/video/del','POST', param, callback);

// 课程
const courseIndex = (param, callback) => fetch('/admin/course/course/index','GET', param, callback);
const courseDetail = (param, callback) => fetch('/admin/course/course/detail','GET', param, callback);
const courseEdit = (param, callback) => fetch('/admin/course/course/edit','POST', param, callback);
const courseDelete = (param, callback) => fetch('/admin/course/course/del','POST', param, callback);
const courseRec = (param, callback) => fetch('/admin/course/course/rec','POST', param, callback);
const courseShenhe = (param, callback) => fetch('/admin/course/course/shenhe','POST', param, callback);

export default {
  courseTeacherIndex,
  courseTeacherDetail,
  courseTeacherEdit,
  courseTeacherDelete,
  courseTeacherRec,

  courseMenuIndex,
  courseMenuDetail,
  courseMenuEdit,
  courseMenuDelete,

  courseVideoIndex,
  courseVideoDetail,
  courseVideoEdit,
  courseVideoDelete,

  courseIndex,
  courseDetail,
  courseEdit,
  courseDelete,
  courseRec,
  courseShenhe

}
