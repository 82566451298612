import { fetch } from '@/utils/request';

// 会员
const memberIndex = (param, callback) => fetch('/admin/member/index','GET', param, callback);
const memberInfo = (param, callback) => fetch('/admin/member/detail','GET', param, callback);
const memberUpadte = (param, callback) => fetch('/admin/member/update','POST', param, callback);
const memberSetGrade = (param, callback) => fetch('/admin/member/setGrade','POST', param, callback);
const memberIntegralChange = (param, callback) => fetch('/admin/member/integral/change','POST', param, callback);
const memberBillChange = (param, callback) => fetch('/admin/member/bill/change','POST', param, callback);

// 会员等级
const memberGradeIndex = (param, callback) => fetch('/admin/member/grade/index','GET', param, callback);
const memberGradeInfo = (param, callback) => fetch('/admin/member/grade/detail','GET', param, callback);
const memberGradeAdd = (param, callback) => fetch('/admin/member/grade/add','POST', param, callback);
const memberGradeEdit = (param, callback) => fetch('/admin/member/grade/edit','POST', param, callback);
const memberGradeShenhe = (param, callback) => fetch('/admin/member/grade/shenhe','POST', param, callback);
const memberGradeDel = (param, callback) => fetch('/admin/member/grade/del','POST', param, callback);

// 会员提现
const memberWithIndex = (param, callback) => fetch('/admin/member/with/index','GET', param, callback);
const memberWithShenhe = (param, callback) => fetch('/admin/member/with/shenhe','POST', param, callback);

export default {
  memberIndex,
  memberInfo,
  memberUpadte,
  memberSetGrade,
  memberIntegralChange,
  memberBillChange,

  memberGradeIndex,
  memberGradeInfo,
  memberGradeAdd,
  memberGradeEdit,
  memberGradeShenhe,
  memberGradeDel,

  memberWithIndex,
  memberWithShenhe

}
